<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import PopUpDetailWorkspace from '@/views/pages/master/schedule/components/modal/main';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import { masterMethods, checkPermission, keyMaster } from '@/state/helpers';
import { OPTIONS_TYPE } from '@/views/pages/master/schedule/constants';
import { showMessage } from '@/utils/messages';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: i18n.t('sheduleFile.list'),
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        PopUpDetailWorkspace,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            loading: false,
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeOpen: [],
            listDataMasterCampaignTypeClose: [],
            initForm: {
                id: '',
                title: '',
                errorTitle: '',
                optionsType: OPTIONS_TYPE,
                typeCampaign: '',
                errorTypeCampaign: '',
                listPlatform: [],
                textWorkspace: '',
                workspace: [],
                errorWorkspace: ''
            },
            codeWS: '',
            isValidateDetailWork: true,
            configModal: {
                detailTitle: '',
                codeWS: '',
                isShowModal: false,
                dataWorks: []
            },
            dataDetailWork: []
        };
    },

    mounted() {
        this.getListMasterCampaignType();
        if (this.$route.query.id) this.getDetail();
    },
    methods: {
        checkPermission,
        ...masterMethods,

        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                data.forEach((item) => {
                    if (item.value == 'クローズ') {
                        this.listDataMasterCampaignTypeClose = item.data;
                    } else {
                        this.listDataMasterCampaignTypeOpen = item.data;
                    }
                });
                this.listDataMasterCampaignType = [...this.listDataMasterCampaignTypeOpen, ...this.listDataMasterCampaignTypeClose];
            });
        },

        handleValidate(field) {
            let check = true;
            if (!field) {
                if (this.initForm.title === '') {
                    this.initForm.errorTitle = i18n.t('validateField.required', {
                        field: i18n.t('sheduleFile.title')
                    });
                    check = false;
                } else {
                    if (this.initForm.title.length >= 255) {
                        this.initForm.errorTitle = i18n.t('validateField.max255', {
                            field: i18n.t('sheduleFile.title')
                        });
                        check = false;
                    }
                }
                // typeCampaign
                if (this.initForm.typeCampaign === '') {
                    this.initForm.errorTypeCampaign = i18n.t('validateField.requiredSelect', {
                        field: i18n.t('sheduleFile.typeCP')
                    });
                    check = false;
                }
            } else {
                if (field === 'title') {
                    if (this.initForm.title === '') {
                        this.initForm.errorTitle = i18n.t('validateField.required', {
                            field: i18n.t('sheduleFile.title')
                        });
                    } else {
                        if (this.initForm.title.length >= 255) {
                            this.initForm.errorTitle = i18n.t('validateField.max255', {
                                field: i18n.t('sheduleFile.title')
                            });
                        } else {
                            this.initForm.errorTitle = '';
                        }
                    }
                }
                // typeCampaign
                if (field === 'typeCampaign') {
                    if (this.initForm.typeCampaign === '') {
                        this.initForm.errorTypeCampaign = i18n.t('validateField.requiredSelect', {
                            field: i18n.t('sheduleFile.typeCP')
                        });
                    } else {
                        this.initForm.errorTypeCampaign = '';
                    }
                }
            }
            return check;
        },

        handleAddWorkspace() {
            if (!this.initForm.textWorkspace) return;
            let count = this.initForm.workspace.length + 1;
            this.initForm.workspace.push({
                idWS: count,
                work_title: this.initForm.textWorkspace,
                details: []
            });
            this.initForm.textWorkspace = '';
        },

        handleDeleteWorkspace(obj) {
            Swal.fire({
                title: this.$t('sheduleFile.msgDeleteWork'),
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    let id = obj.id ? obj.id : obj.idWS;
                    this.initForm.workspace.splice(
                        this.initForm.workspace.findIndex((item) => {
                            let idTemp = item.id ? item.id : item.idWS;
                            return idTemp === id;
                        }),
                        1
                    );
                }
            });
        },

        handleOpenModal(obj) {
            this.configModal.detailTitle = obj.work_title;
            this.configModal.codeWS = obj.id ? obj.id : obj.idWS;
            this.configModal.isShowModal = true;
            this.configModal.dataWorks = obj.details;
        },

        handleRegiter(dataObject) {
            this.createScheduleFile(dataObject)
                .then((result) => {
                    if (result.code !== 200) {
                        showMessage(result.code, this.$bvToast, this.$t(result.message));
                        this.loading = false;
                        return;
                    }
                    this.$router
                        .push({
                            path: '/master/schedule/add',
                            query: {
                                id: result.data.id
                            }
                        })
                        .then(() => {
                            showMessage(result.code, this.$bvToast, this.$t('sheduleFile.registerSuccess'));
                        });
                    this.loading = false;
                })
                .catch((e) => {
                    showMessage(422, this.$bvToast, this.$t(e.message));
                    this.loading = false;
                });
        },

        handleUpdate(dataObject) {
            this.updateScheduleFile(dataObject)
                .then((result) => {
                    if (result.code !== 200) {
                        showMessage(result.code, this.$bvToast, this.$t(result.message));
                        this.loading = false;
                        return;
                    }
                    showMessage(result.code, this.$bvToast, this.$t('sheduleFile.updateSuccess'));
                    this.getDetail();
                })
                .catch((e) => {
                    showMessage(422, this.$bvToast, this.$t(e.message));
                    this.loading = false;
                });
        },

        handleSubmit() {
            let check = this.handleValidate();
            if (!check || !this.isValidateDetailWork) return;
            this.loading = true;
            let dataObject = {
                title: this.initForm.title,
                type: this.initForm.typeCampaign,
                platforms: this.initForm.listPlatform,
                works: this.initForm.workspace.map((x) => {
                    delete x.idWS;
                    return x;
                })
            };

            if (this.$route.query.id) {
                dataObject.id = this.$route.query.id;
                this.handleUpdate(dataObject);
            } else {
                this.handleRegiter(dataObject);
            }
        },

        getDetail() {
            this.loading = true;
            this.getDetailScheduleFile(this.$route.query.id).then((data) => {
                let dataResponse = data.data;
                this.initForm.title = dataResponse.title;
                this.initForm.typeCampaign = dataResponse.type;
                this.initForm.listPlatform = dataResponse.platforms.map((item) => item.id);
                this.initForm.workspace = dataResponse.works;
                this.loading = false;
            });
        }
    },
    watch: {
        dataDetailWork: function (obj) {
            const objectWS = this.initForm.workspace.find((item) => {
                let idTemp = item.id ? item.id : item.idWS;
                if (idTemp === obj.code) return item;
            });
            if (!objectWS) return;
            objectWS.details = obj.data;
        },
        isValidateDetailWork: function (value) {
            this.isValidateDetailWork = value;
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading" class="row box-select2">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="handleSubmit" @keydown.enter.prevent autocomplete="off">
                            <div class="form-group row">
                                <label class="col-sm-3" for="validationCustom05"
                                    >{{ $t('sheduleFile.title') }} <span class="text-danger">*</span></label
                                >
                                <div class="col-sm-9">
                                    <input
                                        id="validationCustom05"
                                        v-model="initForm.title"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': initForm.errorTitle
                                        }"
                                        @blur="handleValidate('title')"
                                    />
                                    <div v-if="initForm.errorTitle" class="invalid-feedback">
                                        <span>{{ initForm.errorTitle }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3" for="validationCustom05"
                                    >{{ $t('sheduleFile.typeCP') }} <span class="text-danger">*</span></label
                                >
                                <div class="col-sm-9">
                                    <div class="d-flex">
                                        <div class="custom-control custom-radio mr-4" v-for="item in initForm.optionsType" :key="item.value + 's'">
                                            <input
                                                type="radio"
                                                v-model="initForm.typeCampaign"
                                                :id="`check${item.value}`"
                                                :value="item.value"
                                                class="custom-control-input"
                                                @change="handleValidate('typeCampaign')"
                                            />
                                            <label :for="`check${item.value}`" class="custom-control-label">{{ item.label }}</label>
                                        </div>
                                    </div>
                                    <div v-if="initForm.errorTypeCampaign" class="custom-control invalid-feedback pl-0">
                                        <span>{{ initForm.errorTypeCampaign }}</span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="initForm.typeCampaign === 2">
                                <div class="form-group row">
                                    <label class="col-sm-3" for="validationCustom05"></label>
                                    <div class="col-sm-9 d-flex align-items-center">
                                        <p class="col-sm-2 font-weight-bold m-0" style="min-width: fit-content">
                                            {{ $t('faq.Open Cp') }}
                                        </p>
                                        <div class="d-flex flex-wrap">
                                            <div
                                                class="custom-control custom-checkbox mr-4 mb-2"
                                                v-for="item in listDataMasterCampaignTypeOpen"
                                                :key="item.id + 'o'"
                                            >
                                                <input
                                                    type="checkbox"
                                                    v-model="initForm.listPlatform"
                                                    :id="`check${item.id}`"
                                                    :value="item.id"
                                                    class="custom-control-input"
                                                />
                                                <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-3" for="validationCustom05"></label>
                                    <div class="col-sm-9 d-flex align-items-center">
                                        <p class="col-sm-2 font-weight-bold m-0" style="min-width: fit-content">
                                            {{ $t('faq.Close Cp') }}
                                        </p>
                                        <div class="d-flex flex-wrap">
                                            <div
                                                class="custom-control custom-checkbox mr-4 mb-2"
                                                v-for="item in listDataMasterCampaignTypeClose"
                                                :key="item.id + 'c'"
                                            >
                                                <input
                                                    type="checkbox"
                                                    v-model="initForm.listPlatform"
                                                    :id="`check${item.id}`"
                                                    :value="item.id"
                                                    class="custom-control-input"
                                                />
                                                <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3" for="validationCustom05">{{ $t('sheduleFile.works') }}</label>
                                <div class="col-sm-9 d-flex">
                                    <input id="validationCustom05" v-model="initForm.textWorkspace" type="text" class="form-control" />
                                    <button type="button" class="btn btn-success ml-3" style="min-width: fit-content" @click="handleAddWorkspace">
                                        {{ $t('btn.addTag') }}
                                    </button>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3" for="validationCustom05"></label>
                                <div class="col-sm-9" style="height: 300px; overflow-y: auto">
                                    <div
                                        class="d-flex align-items-center mb-3 py-2"
                                        style="justify-content: space-between"
                                        v-for="(wp, idx) in initForm.workspace"
                                        :key="idx"
                                    >
                                        <div class="col-sm-10 p-2" style="border-radius: 5px; border: 1px solid #e8e8e8">
                                            {{ wp.work_title }}
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button
                                                v-b-modal="`${wp.idWS}-modal`"
                                                type="button"
                                                class="btn btn-primary ml-3 border"
                                                style="min-width: fit-content"
                                                @click="handleOpenModal(wp)"
                                            >
                                                {{ $t('btn.detail') }}
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-danger ml-3"
                                                style="min-width: fit-content"
                                                @click="handleDeleteWorkspace(wp)"
                                            >
                                                {{ $t('btn.delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/master/schedule/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button type="submit" v-if="checkPermission('master.register')" class="btn btn-primary ml-3" @click="handleSubmit">
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center">
                <LoadingIcon />
            </div>
        </div>
        <PopUpDetailWorkspace :config="configModal" :validateDetail.sync="isValidateDetailWork" :data.sync="dataDetailWork" />
    </Layout>
</template>
<style></style>
